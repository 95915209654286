export const FILE_TYPE_FROM_MIME = {
  'application/pdf': 'dopdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'document',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'presentation',
} as const;

export const COLUMNS_TRANSLATION_IDS = {
  name: 'storage.browserHeader.name',
  due_date: undefined,
  status: 'storage.browserHeader.status',
  'time.modification': 'storage.browserHeader.dateModified',
  shared_with: 'storage.browserHeader.sharedWith',
  owner: 'global.owner',
  'time.creation': 'CREATION_DATE',
  'metadata.veeva_doc_number': 'VEEVA_DOC_NUMBER',
  'metadata.veeva_global_id': 'VEEVA_GLOBAL_ID',
};
