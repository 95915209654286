import { ReactNode, useCallback, useEffect, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import { ThemeProvider } from 'dodoc-design-system';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import * as Sentry from '@sentry/react';
import { updateIntl } from 'react-intl-redux';

import { useCurrentUser, useDispatch, useIEnvision, useSelector } from '_common/hooks';
import { InstanceService } from '_common/services';
import ToastSystemContextProvider from '_common/components/ToastSystem/ToastSystemContext';
import { PageVisibility } from 'Editor/services';
import { getMessages } from 'Intl/Intl';

import {
  getUnreadNotificationsCount,
  setStopUnreadRequest,
} from '_common/components/Popovers/NotificationsPopover/NotificationsSlice';
import { checkActiveAccount } from 'Auth/redux/authSlice';
import { openModal } from '_common/modals/ModalsSlice';
import {
  setAppLoading,
  setAppLocation,
  storeAdblockUsage,
  storePlatformInfo,
} from 'App/redux/appSlice';
import { useGetObjectQuery } from './redux/objectApi';

import InvalidTenant from './InvalidTenant/InvalidTenant';
import MobileNotSupported from './MobileNotSupported/MobileNotSupported';
import AppModalConductor from './AppModalConductor';
import {
  NewVersionAvailableBanner,
  ConnectivityIssuesBanner,
  PageLoader,
  ToastSystem,
} from '_common/components';

import styles from './App.module.scss';
import useNetworkEvents from './useNetworkEvents';
import OnboardingExplorer from 'Storage/components/OnboardingExplorer/OnboardingExplorer';
import ModalProvider from './ModalContext/ModalContext';
import JobsProvider from '_common/components/JobsContext/JobsContext';
import DocumentTitleProvider from '_common/components/DocumentTitle/DocumentTitleContext';

/**
 * Root App component
 *
 * @param {object} props - Component's props
 * @param {boolean} props.loading - True when the initial request is
 * in progress.
 *
 * @return {Component} - Returns a loading indicator when `props.loading`
 * and the router otherwise.
 *
 * @author    José Nunes <jnunes@dodoc.com>
 * @copyright 2017 doDOC
 */
const App = ({ children }: { children?: ReactNode }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const location = useLocation();
  const isIEnvision = useIEnvision();

  const userPingRef = useRef(0);
  const validationRef = useRef(0);
  const notificationsRef = useRef(0);

  const { data: currentUser, refetch } = useCurrentUser();
  const authenticated = useSelector((state) => state.auth.authenticated);
  const userId = useSelector((state) => state.auth.userId);
  const accounts = useSelector((state) => state.localStorage.accounts);
  const isTenantDeactivated = useSelector((state) => state.app.isTenantDeactivated);
  const platform = useSelector((state) => state.app.platform);
  const notificationPopoverOpen = useSelector((state) => state.notifications.popoverOpen);

  const stopUnreadRequest = useSelector((state) => state.notifications.stopUnreadRequest);
  const appTheme = useSelector((state) => state.app.theme);

  /**
   * Save the current location in the redux store
   */
  useEffect(() => {
    dispatch(setAppLocation(location));
  }, [location]);

  //#region Notifications update loop
  // Data to identify the object of the current suite product
  const suiteData = useMemo(() => {
    const locationData = location.pathname.split('/');

    const route = locationData?.[1] || null;
    const objectId: doDOC.SuiteObject['id'] | null = locationData?.[2] || null;
    let objectType: doDOC.SuiteObject['type'] | null = null;

    switch (route) {
      case 'editor': {
        objectType = 'document';
        break;
      }
      case 'pdf': {
        objectType = 'dopdf';
        break;
      }
      case 'presentation': {
        objectType = 'presentation';
        break;
      }

      default: {
        return { objectId: null, objectType: null };
      }
    }

    return { objectId, objectType };
  }, [location]);

  //Get the data of the suite object to see if the object exists
  const { data: object } = useGetObjectQuery(
    {
      objectId: suiteData.objectId || '',
      //@ts-expect-error objectType cant be null, but the "skip" ensures of this
      objectType: suiteData.objectType,
      errorsExpected: [400],
    },
    { skip: !suiteData.objectId || !suiteData.objectType },
  );

  useNetworkEvents();

  //Stop the loop request if the object doesnt exist
  useEffect(() => {
    if ((suiteData.objectId && !object) || location.pathname.includes('/404')) {
      dispatch(setStopUnreadRequest(true));
    } else {
      dispatch(setStopUnreadRequest(false));
    }
  }, [location.pathname, suiteData, object]);

  //Loop to request the unread notifications data
  useEffect(() => {
    if (authenticated && !notificationPopoverOpen && !stopUnreadRequest && !isIEnvision) {
      const filters = suiteData.objectId
        ? {
            filter_fields: ['target'],
            filter_values: [suiteData.objectId || ''],
          }
        : null;

      //Clean up loop (documentId change will generate multiple instead)
      if (notificationsRef.current) {
        clearTimeout(notificationsRef.current);
        notificationsRef.current = 0;
      }

      updateNotifications(filters);
      notificationsRef.current = window.setInterval(() => {
        updateNotifications(filters);
      }, 10000);
    } else if (notificationsRef.current) {
      clearTimeout(notificationsRef.current);
      notificationsRef.current = 0;
    }
  }, [authenticated, notificationPopoverOpen, suiteData, stopUnreadRequest, location, isIEnvision]);

  //Change the filters of the loop request
  const updateNotifications = (filters: Request.FilterParams | null) => {
    dispatch(getUnreadNotificationsCount({ request: filters }));
  };
  //#endregion

  dayjs.extend(utc);
  dayjs.extend(timezone);

  useEffect(() => {
    if (authenticated && currentUser && userId) {
      refetch();
    }
  }, [authenticated, userId]);

  useEffect(() => {
    if (currentUser?.profile.language) {
      dispatch(
        updateIntl({
          locale: currentUser.profile.language,
          messages: getMessages(currentUser.profile.language),
        }),
      );
    }
  }, [currentUser]);

  // Removes the static loading indicator (see /src/templates/index.html)
  useEffect(() => {
    dispatch(setAppLoading({ isOpen: true }));
    const item = document.getElementById('initial-loading');
    if (item) {
      item.parentNode?.removeChild(item);
    }
    identifyPlatform();
    identifyAdblock();
    PageVisibility.getInstance().on('TAB_FOCUS', startUserPing);
    PageVisibility.getInstance().on('TAB_HIDDEN', stopUserPing);
    PageVisibility.getInstance().on('WINDOW_FOCUS', startUserPing);
    PageVisibility.getInstance().on('WINDOW_HIDDEN', stopUserPing);
    PageVisibility.getInstance().start();

    return () => {
      PageVisibility.getInstance().destroy();
      clearTimeoutValidation();
    };
  }, []);

  useEffect(() => {
    if (
      currentUser &&
      currentUser.profile.id &&
      currentUser.profile.timezone !== dayjs.tz.guess() &&
      // dismiss warning permanently
      !localStorage.getItem(`doDOC-DifferentTimezoneWarningModalDismiss-${currentUser.profile.id}`)
    ) {
      dispatch(openModal('DifferentTimezoneWarningModal'));
    }
  }, [currentUser?.profile.id, currentUser?.profile.timezone, dispatch]);

  //#region Check User Active time interval
  const checkTokenValidation = useCallback(() => {
    if (currentUser) {
      dispatch(checkActiveAccount({ account: accounts[currentUser.profile.id] }));
    }
  }, [accounts, currentUser?.profile.id, location.hash, dispatch, location.pathname]);

  const clearTimeoutValidation = useCallback(() => {
    stopUserPing();

    if (validationRef.current) {
      clearTimeout(validationRef.current);
      validationRef.current = 0;
    }
  }, []);

  const startTimeoutValidation = useCallback(() => {
    clearTimeoutValidation();

    if (currentUser && !validationRef.current && accounts[currentUser.profile.id]) {
      const expirationDate = dayjs(accounts[currentUser.profile.id]?.expires);
      const now = dayjs();

      let timeout = expirationDate.diff(now);

      if (timeout <= 0) {
        timeout = 5000;
      }

      validationRef.current = window.setTimeout(() => {
        checkTokenValidation();
        validationRef.current = 0;
      }, timeout);
    }
  }, [accounts, checkTokenValidation, currentUser?.profile.id, clearTimeoutValidation]);

  const startUserPing = useCallback(() => {
    if (authenticated && !userPingRef.current) {
      sendUserPing();
      userPingRef.current = window.setInterval(sendUserPing, 210000);
    }
  }, [authenticated]);

  useEffect(() => {
    if (
      currentUser &&
      authenticated === true &&
      currentUser.profile.id &&
      accounts[currentUser.profile.id]
    ) {
      startTimeoutValidation();
    }
    return () => {
      clearTimeoutValidation();
    };
  }, [
    authenticated,
    currentUser?.profile.id,
    accounts,
    startTimeoutValidation,
    clearTimeoutValidation,
  ]);

  useEffect(() => {
    if (authenticated) {
      startUserPing();
    } else {
      stopUserPing();
      clearTimeoutValidation();
    }
  }, [authenticated, clearTimeoutValidation, startUserPing]);

  const sendUserPing = () => {
    new InstanceService().sendUserPing();
  };

  const stopUserPing = () => {
    clearInterval(userPingRef.current);
    userPingRef.current = 0;
  };
  //#endregion

  /**
   * This function checks whether the browser being used is IE or not and stores
   * the information with redux
   *
   * @return {Boolean}
   */
  const identifyPlatform = () => {
    const platformInfo: Platform = {
      browser: {
        chrome: false,
      },
      os: {},
    };

    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

    const msie = userAgent.indexOf('MSIE ');
    const trident = navigator.userAgent.indexOf('Trident');

    /**
     * please note, that IE11 now returns undefined again for window.chrome
     * and new Opera 30 outputs true for window.chrome and new IE Edge outputs
     * to true now for window.chrome so use the below updated condition
     */

    const isChromium = window.chrome;
    const vendorName = window.navigator.vendor;
    const isOpera = userAgent.indexOf('OPR') > -1;
    const isIEedge = userAgent.indexOf('Edge') > -1;
    const operaMini = userAgent.match(/Opera Mini/i);
    const ieMobile = userAgent.match(/IEMobile/i);

    if (
      isChromium !== null &&
      isChromium !== undefined &&
      vendorName === 'Google Inc.' &&
      isOpera === false &&
      isIEedge === false
    ) {
      platformInfo.browser.chrome = true;
    }

    if (msie >= 0 || trident >= 0) {
      platformInfo.browser.ie = true;
    }
    if (/^((?!chrome|android).)*safari/i.test(userAgent)) {
      platformInfo.browser.safari = true;
    }
    if (/firefox/i.test(userAgent)) {
      platformInfo.browser.firefox = true;
    }
    if (isIEedge) {
      platformInfo.browser.edge = true;
    }
    if (isOpera) {
      platformInfo.browser.opera = true;
    }
    if (operaMini) {
      platformInfo.browser.operaMini = true;
      platformInfo.mobile = true;
    }
    if (ieMobile) {
      platformInfo.browser.ieMobile = true;
      platformInfo.mobile = true;
    }

    const blackBerry = userAgent.match(/BlackBerry/i);
    if (blackBerry) {
      platformInfo.os.blackBerry = true;
      platformInfo.mobile = true;
    }

    if (macosPlatforms.indexOf(platform) !== -1) {
      platformInfo.os.mac = true;
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      platformInfo.os.iOS = true;
      platformInfo.mobile = true;
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      platformInfo.os.windows = true;
    } else if (/Android/.test(userAgent)) {
      platformInfo.os.android = true;
      platformInfo.mobile = true;
    } else if (Object.keys(platformInfo.os).length === 0 && /Linux/.test(platform)) {
      platformInfo.os.linux = true;
    }

    dispatch(storePlatformInfo(platformInfo));
  };

  const identifyAdblock = () => {
    const adURL = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';

    const config: RequestInit = {
      method: 'HEAD',
      mode: 'no-cors',
    };

    fetch(adURL, config)
      .then(() => {
        // The add was able to be loaded
        dispatch(storeAdblockUsage(false));
      })
      .catch(() => {
        // Request failed, most likely from an adblock
        dispatch(storeAdblockUsage(true));
      });
  };

  const captureSentryException = useCallback((error: any) => {
    Sentry.captureException(error);
  }, []);

  if (platform.mobile) {
    return (
      <DocumentTitleProvider baseTitle="doDOC">
        <MobileNotSupported />
      </DocumentTitleProvider>
    );
  }
  if (isTenantDeactivated) {
    return (
      <DocumentTitleProvider baseTitle="doDOC">
        <InvalidTenant />
      </DocumentTitleProvider>
    );
  }
  return (
    <ThemeProvider
      theme={appTheme}
      className={styles.windowContainer}
      sentry={{
        report: captureSentryException,
        errorMessage: intl.formatMessage({ id: 'COULD_NOT_RETRIEVE_INFORMATION' }),
      }}
    >
      <JobsProvider>
        <ToastSystemContextProvider>
          <ModalProvider>
            <DocumentTitleProvider baseTitle="doDOC">
              <>
                <div className={styles.pageContainer}>
                  <NewVersionAvailableBanner />
                  <ConnectivityIssuesBanner />
                  <div className={styles.routerContainer}>
                    <AppModalConductor />
                    {children}
                    <PageLoader />
                    <OnboardingExplorer />
                  </div>
                  <ToastSystem />
                </div>
                <div id="app-overlay" />
              </>
            </DocumentTitleProvider>
          </ModalProvider>
        </ToastSystemContextProvider>
      </JobsProvider>
    </ThemeProvider>
  );
};

export default Sentry.withProfiler(App);
