import { useEffect, useMemo } from 'react';
import { Icon, usePopper } from 'dodoc-design-system';
import { useDispatch, useSelector } from '_common/hooks';
import UserColors from 'Editor/services/UserColors/UserColors';
import EditableTaskCard from 'PDF/RightSidePanel/TasksPanel/TaskCard/EditableTaskCard/EditableTaskCard';
import styles from './Annotation.module.scss';
import { setPulseData } from 'App/redux/onboardingSlice';
import { useOnboardingPDFActive } from '_common/hooks/Onboarding';

const TemporaryTask = ({ pageNum }: { pageNum: number }) => {
  const dispatch = useDispatch();

  const { referenceProps, popperProps } = usePopper({ placement: 'bottom-start' });
  const creatingTask = useSelector((state) => state.pdf.annotations.creating);
  const showCard = !useSelector((state) => state.pdf.general.rightPanel);
  const onboardingPDFIsActive = useOnboardingPDFActive();

  useEffect(() => {
    if (creatingTask) {
      dispatch(setPulseData({ taskId: 'temporary' }));
    } else {
      dispatch(setPulseData({ taskId: undefined }));
    }
    return () => {
      dispatch(setPulseData({ taskId: undefined }));
    };
  }, [creatingTask]);

  const divs = useMemo(() => {
    if (creatingTask && creatingTask.rects) {
      const divs = [];

      for (let i = 0; i < creatingTask.rects.length; i++) {
        const rect = creatingTask.rects[i];
        const left = rect.left - creatingTask.boundingRect.left;
        const top = rect.top - creatingTask.boundingRect.top;
        const width = rect.width;
        const height = rect.height;
        divs.push(
          <div
            key={left + top}
            className={styles.base}
            style={{
              width,
              height,
              left,
              top,
              background: UserColors.CURRENT_USER_COLOR.tint30,
              mixBlendMode: 'multiply',
            }}
          />,
        );
      }
      return divs;
    }
    return null;
  }, [creatingTask]);

  if (!creatingTask || pageNum !== creatingTask.pageNumber) {
    return null;
  }

  return divs ? (
    <div
      className={`${styles.base} ${styles.selected}`}
      style={{
        top: creatingTask.boundingRect.top - creatingTask.pageRect.top,
        left: creatingTask.boundingRect.left - creatingTask.pageRect.left,
        height: creatingTask.boundingRect.height,
        width: creatingTask.boundingRect.width,
      }}
    >
      {divs}
      <div className={`${styles.toggle} ${styles.selected}`} ref={referenceProps.ref}>
        <Icon size={24} icon="TaskGrey" />
      </div>
      {showCard && (
        <div
          className={styles.card}
          ref={popperProps.ref}
          style={popperProps.style}
          onClick={(e) => e.stopPropagation()}
        >
          <EditableTaskCard
            mode="create"
            creating={creatingTask as PDF.TaskCreationData}
            sidebar={false}
            testId="temporary-task"
          />
        </div>
      )}
    </div>
  ) : (
    <>
      <div
        className={`${styles.toggle} ${styles.selected}`}
        style={{
          top: Math.min(
            creatingTask.boundingRect.top - creatingTask.pageRect.top,
            creatingTask.pageRect.height - creatingTask.boundingRect.height,
          ),
          left: Math.min(
            creatingTask.boundingRect.left - creatingTask.pageRect.left,
            creatingTask.pageRect.width - creatingTask.boundingRect.width,
          ),

          height: creatingTask.boundingRect.height,
          width: creatingTask.boundingRect.width,
        }}
        ref={referenceProps.ref}
      >
        <Icon size={24} icon="TaskGrey" />
      </div>
      {showCard && (
        <div
          className={`${styles.card} ${onboardingPDFIsActive && styles.cardOverlap}`}
          ref={popperProps.ref}
          style={popperProps.style}
          onClick={(e) => e.stopPropagation()}
        >
          <EditableTaskCard
            mode="create"
            creating={creatingTask as PDF.TaskCreationData}
            sidebar={false}
            testId="temporary-task"
          />
        </div>
      )}
    </>
  );
};

export default TemporaryTask;
