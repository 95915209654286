import { Button } from 'dodoc-design-system';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useIEnvision } from '_common/hooks';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';

type ShareButtonProps = {
  object: doDOC.SuiteObject;
};

const ShareButton = ({ object }: ShareButtonProps) => {
  const dispatch = useDispatch();
  const isIEnvision = useIEnvision();

  const canShare =
    (['admin', 'owner'] as const).some((permission) =>
      object.user_permissions.includes(permission),
    ) || isIEnvision;

  const handleShare = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'ShareModal',
        data: {
          view: 'users',
          objectId: object?.id,
          objectType: object?.type,
          editor: true,
        },
      }),
    );
  };

  return (
    <Button
      variant="primary"
      size="medium"
      margin="0 0 0 2rem"
      disabled={!canShare}
      onClick={handleShare}
      testId="share-button"
    >
      <FormattedMessage id="storage.actionBar.actions.share" />
    </Button>
  );
};

export default ShareButton;
