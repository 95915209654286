import { useSelector } from '_common/hooks';
import { isOnboardingActive } from './isOnboardingActive';
import type { OnboardingEnvironments } from 'App/redux/onboardingSlice';

export const useOnboardingActive = (enviroment: OnboardingEnvironments) => {
  const state = useSelector((state) => state);
  return isOnboardingActive(state, enviroment);
};

export const useOnboardingExplorerActive = () => {
  return useOnboardingActive('explorer');
};
export const useOnboardingEditorActive = () => {
  return useOnboardingActive('editor');
};
export const useOnboardingPDFActive = () => {
  return useOnboardingActive('dopdf');
};
export const useOnboardingPresentationActive = () => {
  return useOnboardingActive('presentation');
};
