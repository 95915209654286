import { MouseEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Dropdown, Tooltip } from 'dodoc-design-system';

import { usePDFContext } from 'PDF/PDFContext';
import { useSelector, useDispatch } from '_common/hooks';
import { setEditingAnnotation } from 'PDF/redux/PDFAnnotationsSlice';
import { setCursorMode } from 'PDF/redux/PDFGeneralSlice';
import { usePDFPermissions } from 'PDF/PDFPermissionsContext';
import { InteractionController } from '_common/components';
import { useOnboardingPDFActive } from '_common/hooks/Onboarding';

type PDFContextMenuProps = {
  popperProps: any;
  pageNum: number;
};

const PDFContextMenu = ({ popperProps, pageNum }: PDFContextMenuProps) => {
  const manager = usePDFContext();
  const dispatch = useDispatch();
  const intl = useIntl();
  const hasSelection = manager.isSelectionInPDF() && !manager.isSelectionCollapsed();

  const inVersionHistory = useSelector((state) => state.pdf.general.versionHistory);

  const { canCreateAnnotation, canCreateTask } = usePDFPermissions();

  const disabled = !hasSelection || inVersionHistory || !canCreateAnnotation;
  const onboardingPDFIsActive = useOnboardingPDFActive();

  const highlightText = (subtype: PDF.Annotation.TextMarkup['subtype']) => {
    manager.restoreSelection();
    return manager.createTextMarkupAnnotation(subtype);
  };

  const newComment = () => {
    const id = highlightText('Highlight');
    if (id) {
      dispatch(setEditingAnnotation({ id, isTask: false }));
    } /* else {
      const id = manager.creaetNoteAnnotation(new DOMRect(100, 100), pageNum); // Need coordinates
      if (id) {
        dispatch(setCursorMode('normal'));
        dispatch(setEditingAnnotation(id));
      }
    } */
  };

  const newTask = (e: MouseEvent<HTMLDivElement>) => {
    manager.restoreSelection();
    if (manager.isSelectionInPDF()) {
      manager.startCreatingInlineTask();
    } /* else {
      manager.startCreatingFreestyleTask(new DOMRect(100, 100), pageNum); // Need coordinates
    } */
    dispatch(setCursorMode('normal'));
  };

  return (
    <InteractionController environment="dopdf">
      <div style={{ zIndex: onboardingPDFIsActive ? 1052 : 0 }}>
        <Dropdown {...popperProps}>
          <Tooltip
            content={intl.formatMessage({ id: 'CANNOT_PERFORM_THIS_ACTION_IN_VERSION_HISTORY' })}
            placement="right"
            disabled={!inVersionHistory}
            testId="highlight-annotation-dropdown-item-tooltip"
          >
            <Dropdown.Item
              disabled={disabled}
              size="medium"
              onClick={() => highlightText('Highlight')}
              testId="highlight-annotation-dropdown-item"
            >
              <FormattedMessage id="HIGHLIGHT_TEXT" />
            </Dropdown.Item>
          </Tooltip>
          <Tooltip
            content={intl.formatMessage({ id: 'CANNOT_PERFORM_THIS_ACTION_IN_VERSION_HISTORY' })}
            placement="right"
            disabled={!inVersionHistory}
            testId="strikethrough-annotation-dropdown-item-tooltip"
          >
            <Dropdown.Item
              disabled={disabled}
              size="medium"
              onClick={() => highlightText('StrikeOut')}
              testId="strikethrough-annotation-dropdown-item"
            >
              <FormattedMessage id="STRIKETHROUGH_TEXT" />
            </Dropdown.Item>
          </Tooltip>
          <Tooltip
            content={intl.formatMessage({ id: 'CANNOT_PERFORM_THIS_ACTION_IN_VERSION_HISTORY' })}
            placement="right"
            disabled={!inVersionHistory}
            testId="underline-annotation-dropdown-item-tooltip"
          >
            <Dropdown.Item
              disabled={disabled}
              size="medium"
              onClick={() => highlightText('Underline')}
              testId="underline-annotation-dropdown-item"
            >
              <FormattedMessage id="UNDERLINE_TEXT" />
            </Dropdown.Item>
          </Tooltip>
          <Tooltip
            content={intl.formatMessage({ id: 'CANNOT_PERFORM_THIS_ACTION_IN_VERSION_HISTORY' })}
            placement="right"
            disabled={!inVersionHistory}
            testId="comment-annotation-dropdown-item-tooltip"
          >
            <Dropdown.Item
              disabled={disabled}
              size="medium"
              onClick={newComment}
              testId="comment-annotation-dropdown-item"
            >
              <FormattedMessage id="NEW_COMMENT" />
            </Dropdown.Item>
          </Tooltip>
          <Tooltip
            content={intl.formatMessage({
              id: !canCreateTask
                ? 'NO_PERMISSION_TO_PERFORM_ACTION'
                : 'CANNOT_PERFORM_THIS_ACTION_IN_VERSION_HISTORY',
            })}
            placement="right"
            disabled={!inVersionHistory && canCreateTask}
            testId="task-annotation-dropdown-item-tooltip"
          >
            <Dropdown.Item
              disabled={disabled || !canCreateTask}
              size="medium"
              onClick={newTask}
              testId="task-annotation-dropdown-item"
            >
              <FormattedMessage id="NEW_TASK" />
            </Dropdown.Item>
          </Tooltip>
        </Dropdown>
      </div>
    </InteractionController>
  );
};

export default PDFContextMenu;
