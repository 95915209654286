import { useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks/redux';
import { selectShareModalValues } from 'App/redux/appSlice';

import { InteractionController, SearchUser } from '_common/components';
import type { UserOption } from '_common/components/SearchUser/SearchUser';

import RoleSelect from './RoleSelect';

import styles from './ShareModal.module.scss';
import { useAddRolesMutation, useGetObjectQuery } from 'App/redux/objectApi';
import { completeAction, setPulseData } from 'App/redux/onboardingSlice';
import { useOnboardingExplorerActive } from '_common/hooks/Onboarding';

type ShareContainerProps = {
  applyToDescendants: boolean;
  isDisabled: boolean;
  roles: any[];
};

const ShareContainer = ({ applyToDescendants, isDisabled, roles }: ShareContainerProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [users, setUsers] = useState<UserOption[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<UserOption[]>([]);
  const { usersList, groupsList } = useSelector(selectShareModalValues);
  const objectId = useSelector((state) => state.modals.ShareModal.objectId);
  const objectType = useSelector((state) => state.modals.ShareModal.objectType);
  const actionsCompleted = useSelector((state) => state.onboarding.actionsCompleted);
  const onboardingExplorerIsActive = useOnboardingExplorerActive();
  const [addRoles] = useAddRolesMutation();
  const { data: object, refetch } = useGetObjectQuery(
    { objectId, objectType },
    { skip: !objectId && !objectType },
  );
  const valuesToFilter = useMemo(() => [...usersList, ...groupsList], [usersList, groupsList]);
  const userRef = useRef<HTMLDivElement>(null);
  const roleRef = useRef<HTMLDivElement>(null);
  const ctaRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    refetch();
  }, [users]);

  useEffect(() => {
    if (users.length > 0) {
      dispatch(completeAction('explorer_share_user'));
    }
    if (selectedRoles.length > 0) {
      dispatch(completeAction('explorer_share_role'));
    }
    if (!actionsCompleted.explorer_share_user) {
      if (userRef?.current) {
        dispatch(
          setPulseData({
            shareButtonRect: undefined,
            shareInputRect: {
              top: userRef.current.offsetTop,
              left: userRef.current.offsetLeft,
              height: userRef.current.offsetHeight,
              width: userRef.current.offsetWidth,
            },
            shareRoleRect: undefined,
          }),
        );
      } else {
        dispatch(
          setPulseData({
            shareButtonRect: undefined,
            shareInputRect: undefined,
            shareRoleRect: undefined,
          }),
        );
      }
    } else if (!actionsCompleted.explorer_share_role) {
      if (roleRef?.current) {
        dispatch(
          setPulseData({
            shareButtonRect: undefined,
            shareInputRect: undefined,
            shareRoleRect: {
              top: roleRef.current.offsetTop,
              left: roleRef.current.offsetLeft,
              height: roleRef.current.offsetHeight,
              width: roleRef.current.offsetWidth,
            },
          }),
        );
      } else {
        dispatch(
          setPulseData({
            shareButtonRect: undefined,
            shareInputRect: undefined,
            shareRoleRect: undefined,
          }),
        );
      }
    } else {
      if (ctaRef?.current)
        dispatch(
          setPulseData({
            shareButtonRect: {
              top: ctaRef.current.offsetTop,
              left: ctaRef.current.offsetLeft,
              height: ctaRef.current.offsetHeight,
              width: ctaRef.current.offsetWidth,
            },
            shareInputRect: undefined,
            shareRoleRect: undefined,
          }),
        );
    }
  }, [users, selectedRoles, actionsCompleted]);

  const share = () => {
    if (users.length > 0) {
      dispatch(completeAction('explorer_share_finish'));
      users.forEach((user) => {
        if (!onboardingExplorerIsActive) {
          addRoles({
            objectId: object?.id ?? objectId,
            objectType: object?.type ?? objectType,
            params: {
              [user.type]: user.value,
              roles: selectedRoles.map((role) => role.value),
              recursive: applyToDescendants,
            },
          });
        }
      });
      setUsers([]);
      setSelectedRoles([]);
    }
  };

  return (
    <div className={styles.shareContainer} id="share-container">
      <div style={{ marginRight: '1rem' }} ref={userRef}>
        <InteractionController environment="explorer" rules={[{ interaction: 'explorer_share' }]}>
          <SearchUser
            value={users}
            valuesToFilter={valuesToFilter}
            onChange={setUsers}
            both
            size="large"
            disabled={isDisabled}
            menuPosition="fixed"
            width="35rem"
            placeholder={intl.formatMessage({ id: 'NAME_EMAIL_OR_GROUP' })}
            isMulti
            defaultOptions={users}
            searchable
            testId="share-modal"
            menuLabel={intl.formatMessage({
              id: 'START_TYPING_TO_SEE_THE_LIST_OF_USERS_OR_GROUPS',
            })}
          />
        </InteractionController>
      </div>
      <div ref={roleRef}>
        <InteractionController environment="explorer" rules={[{ interaction: 'explorer_share' }]}>
          <RoleSelect
            width="22rem"
            size="large"
            value={selectedRoles}
            onChange={setSelectedRoles}
            options={roles}
            disabled={isDisabled}
            placeholder={intl.formatMessage({ id: 'SELECT_ROLE' })}
            testId="share-modal-role"
          />
        </InteractionController>
      </div>
      <InteractionController environment="explorer" rules={[{ interaction: 'explorer_share' }]}>
        <Button
          margin="0 0 0 2rem"
          variant="primary"
          size="large"
          onClick={share}
          disabled={selectedRoles.length === 0 || users.length === 0}
          fullWidth
          style={{ maxWidth: '12rem' }}
          testId="share-modal-share-button"
          ref={ctaRef}
        >
          <FormattedMessage id="storage.modals.share.title" />
        </Button>
      </InteractionController>
    </div>
  );
};

export default ShareContainer;
