import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Modal, Input, InputField, TextArea } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks/redux';

import { closeAndResetModal } from '_common/modals/ModalsSlice';
import { useCreateRoleMutation } from 'Settings/pages/TenantSettingsPage/Roles/RolesApi';
import { useCreateSpaceMutation } from '_common/services/api/ObjectApi';
import { completeAction, setPulseData } from 'App/redux/onboardingSlice';
import { InteractionController } from '_common/components';
import { useOnboardingExplorerActive } from '_common/hooks/Onboarding';

const MODAL = 'CreateNewObjectModal';

const CreateNewObjectModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const isOpen = useSelector((state) => state.modals.open[MODAL]);
  const title = useSelector((state) => state.modals[MODAL].title);
  const nameLabel = useSelector((state) => state.modals[MODAL].nameLabel);
  const namePlaceholder = useSelector((state) => state.modals[MODAL].namePlaceholder);
  const descriptionLabel = useSelector((state) => state.modals[MODAL].descriptionLabel);
  const descriptionPlaceholder = useSelector((state) => state.modals[MODAL].descriptionPlaceholder);
  const action = useSelector((state) => state.modals[MODAL].action);
  const actionsCompleted = useSelector((state) => state.onboarding.actionsCompleted);
  const onboardingExplorerIsActive = useOnboardingExplorerActive();

  const [mutCreateRole] = useCreateRoleMutation();
  const [createSpace] = useCreateSpaceMutation();

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [validations, setValidations] = useState<{ name?: string; description?: string }>({
    name: '',
    description: '',
  });
  const inputRef = useRef<HTMLInputElement>(null);
  const ctaRef = useRef<HTMLButtonElement>(null);

  const ACTIONS: { [key: string]: () => void } = {
    createSpace: () => {
      createSpace({ name, description });
      close();
    },
    createRole: () => {
      mutCreateRole({ name, description });
      close();
    },
  };

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 0);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!name) {
      const current = inputRef?.current;
      if (current) {
        dispatch(
          setPulseData({
            spaceInputRect: {
              top: current.offsetTop,
              left: current.offsetLeft,
              height: current.offsetHeight,
              width: current.offsetWidth,
            },
          }),
        );
      } else {
        dispatch(
          setPulseData({
            spaceButtonRect: undefined,
            spaceInputRect: undefined,
          }),
        );
      }
    } else {
      if (ctaRef?.current) {
        dispatch(
          setPulseData({
            spaceButtonRect: {
              top: ctaRef.current.offsetTop,
              left: ctaRef.current.offsetLeft,
              height: ctaRef.current.offsetHeight,
              width: ctaRef.current.offsetWidth,
            },
            spaceInputRect: undefined,
          }),
        );
      }
    }

    return () => {
      dispatch(
        setPulseData({
          spaceInputRect: undefined,
        }),
      );
    };
  }, [name, actionsCompleted]);

  const close = () => {
    setName('');
    setDescription('');
    setValidations({ name: '', description: '' });
    dispatch(closeAndResetModal(MODAL));
  };

  const onNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputName = e.target.value;
    setName(inputName);
    // validations
    const validations = { name: '' };
    if (inputName.length === 0) {
      validations.name = intl.formatMessage({
        id: 'validation.name.insertName',
      });
    } else if (inputName.length > 150) {
      validations.name = intl.formatMessage({ id: 'validation.name.longName' }, { length: 150 });
    } else {
      validations.name = '';
    }

    if (Object.keys(validations).length > 0) {
      setValidations(validations);
    }
  };

  const handleNew = () => {
    if (ACTIONS[action]) {
      ACTIONS[action]();
      dispatch(completeAction('explorer_spaces_space_created'));
    }
  };

  const onDescriptionChanged = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputDescription = e.target.value;
    setDescription(inputDescription);
    const validations = { description: '' };

    if (inputDescription.length > 1000) {
      validations.description = intl.formatMessage({
        id: 'validation.description.longDescription',
      });
    } else {
      validations.description = '';
    }
    if (Object.keys(validations).length > 0) {
      setValidations(validations);
    }
  };

  return (
    <Modal
      width="75rem"
      open={!!isOpen}
      onClose={onboardingExplorerIsActive ? undefined : close}
      testId="create-new-object"
    >
      <div id="create-new-object-modal">
        <InteractionController
          environment="explorer"
          style={{ width: '100%', flexDirection: 'column' }}
        >
          <Modal.Header onClose={close}>{title}</Modal.Header>
        </InteractionController>
        <Modal.Body>
          <InputField
            label={nameLabel}
            size="large"
            feedback={validations.name ? validations.name : undefined}
            testId="create-new-object-name"
          >
            <InteractionController
              environment="explorer"
              rules={[{ interaction: 'explorer_space_create' }]}
              style={{ width: '100%', flexDirection: 'column' }}
            >
              <Input
                value={name}
                size="large"
                onChange={onNameChanged}
                placeholder={namePlaceholder}
                ref={inputRef}
                error={!!validations.name}
                onEnterKey={handleNew}
                testId="create-new-object-name"
              />
            </InteractionController>
          </InputField>
          <InputField
            label={descriptionLabel}
            size="large"
            feedback={validations.description}
            testId="create-new-object-description"
          >
            <InteractionController
              environment="explorer"
              style={{ width: '100%', flexDirection: 'column' }}
            >
              <TextArea
                size="large"
                value={description}
                onChange={onDescriptionChanged}
                placeholder={descriptionPlaceholder}
                error={!!validations.description}
                testId="create-new-object-description"
              />
            </InteractionController>
          </InputField>
        </Modal.Body>
        <Modal.Footer>
          <InteractionController environment="explorer" style={{ flexDirection: 'column' }}>
            <Button size="medium" onClick={close} testId="create-new-object-cancel-button">
              <FormattedMessage id="global.cancel" />
            </Button>
          </InteractionController>
          <InteractionController
            environment="explorer"
            rules={[{ interaction: 'explorer_space_create' }]}
            style={{ flexDirection: 'column', marginLeft: '1rem' }}
          >
            <Button
              size="medium"
              variant="primary"
              onClick={handleNew}
              disabled={name === '' || !!validations.name || !!validations.description}
              testId="create-new-object-submit-button"
              ref={ctaRef}
            >
              <FormattedMessage id="global.create" />
            </Button>
          </InteractionController>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default CreateNewObjectModal;
