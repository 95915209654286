import {
  useGetCurrentUserQuery,
  selectUserCurrentTenant,
  useGetLinksQuery,
} from '_common/services/api/authority';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const useVeevaIntegration = () => {
  const { data: currentUser } = useGetCurrentUserQuery();
  const currentTenant = useSelector(selectUserCurrentTenant);

  const { data: links } = useGetLinksQuery(
    { userId: currentUser?.profile.id ?? '', tenant: currentTenant?.name ?? '' },
    { skip: !currentUser?.other.tenants || !currentTenant },
  );

  const hasVeevaIntegration = useMemo(() => {
    return (
      links?.providers &&
      links.providers.length > 0 &&
      links.providers.find(
        (provider) =>
          provider.name.toLocaleLowerCase() === 'veeva' ||
          provider.name.toLocaleLowerCase() === 'jti',
      )
    );
  }, [links]);

  return hasVeevaIntegration;
};

export default useVeevaIntegration;
