import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { uniq } from 'lodash';

import { useSelector } from '_common/hooks';
import { useGetRolesListQuery } from 'Settings/pages/TenantSettingsPage/Roles/RolesApi';
import { useAddRolesMutation, useRemoveRolesMutation } from 'App/redux/objectApi';

import { usePermissions } from '_common/components/Permissions/PermissionsContext';
import RoleSelect, { RoleOption, RoleSelectProps } from '_common/modals/ShareModal/RoleSelect';
import { useOnboardingExplorerActive } from '_common/hooks/Onboarding';

type RolesColumnProps = {
  objectId: ObjectId;
  collaborator: Permission.Collaborator;
  recursive: boolean;
  openDeleteLastPermissionModal: (
    collaboratorId: Permission.Collaborator['collaboratorId'],
    collaboratorType: Permission.Collaborator['collaboratorType'],
    code?: Permission.CollaboratorPermission,
  ) => void;
  isLastPermission: (
    collaboratorId: Permission.Collaborator['collaboratorId'],
    collaboratorType: Permission.Collaborator['collaboratorType'],
    clearRoles?: boolean,
  ) => boolean;
};

const RolesColumn = ({
  objectId,
  collaborator,
  recursive,
  openDeleteLastPermissionModal,
  isLastPermission,
}: RolesColumnProps) => {
  const intl = useIntl();

  const data = useSelector((state) => state.app.data[objectId]);
  const userId = useSelector((state) => state.auth.userId);
  const onboardingExplorerIsActive = useOnboardingExplorerActive();
  const { data: roles } = useGetRolesListQuery();

  const [addRoles] = useAddRolesMutation();
  const [removeRoles] = useRemoveRolesMutation();

  const { canEditPermissions, canEditRoles } = usePermissions();

  const { collaboratorId, collaboratorType } = collaborator;

  const formKey = collaboratorType === 'user' ? 'users' : 'groups';

  const parsedRoles = useMemo(() => {
    return onboardingExplorerIsActive
      ? [{ value: 'writer', label: 'Writer', description: 'No description' }]
      : roles?.list.map((id) => ({
          value: id,
          label: roles.dict[id].name,
          description: roles.dict[id].description || intl.formatMessage({ id: 'NO_DESCRIPTION' }),
        }));
  }, [roles]);

  const userRoles = useMemo((): RoleOption[] => {
    const userRoles: RoleOption[] = [];

    uniq(data.permissions.roles[formKey][collaboratorId]).forEach((roleId) => {
      const role = parsedRoles?.find((role) => role.value === roleId);

      if (role) {
        userRoles.push(role);
      }
    });

    return userRoles;
  }, [data, parsedRoles, formKey, collaboratorId]);

  const getRolesName = (userRoles: RoleOption[]) => {
    if (!userRoles || userRoles.length === 0) {
      return intl.formatMessage({
        id: 'storage.modals.share.noRoleSelected',
      });
    }

    if (userRoles.length === 1 && roles) {
      return roles.dict[userRoles[0]?.value].name;
    }

    return intl.formatMessage(
      { id: 'storage.modals.share.rolesSelected' },
      { count: userRoles.length },
    );
  };

  // Return array with ids to disable in RoleSelect.
  const getDisabledRoles = () => {
    const disabledRoles: string[] = [];

    if (data.user_permissions.includes('owner') || data.user_permissions.includes('admin')) {
      return disabledRoles;
    }

    if (collaboratorId === userId && roles) {
      return roles.list;
    }

    roles?.list.forEach((id) => {
      const hasSamePermissions = roles.dict[id].granted.some(
        (permission) => !data.user_permissions.includes(permission),
      );
      const hasActiveRolesToDisable =
        data.permissions.roles[formKey][collaboratorId] &&
        data.permissions.roles[formKey][collaboratorId].includes(id);
      const hasInactiveRolesToDisable =
        !data.permissions.roles[formKey][collaboratorId] ||
        (data.permissions.roles[formKey][collaboratorId] &&
          !data.permissions.roles[formKey][collaboratorId].includes(id));

      if (
        hasSamePermissions ||
        (data.user_permissions.includes('add_permission') &&
          !data.user_permissions.includes('remove_permission') &&
          hasActiveRolesToDisable) ||
        (!data.user_permissions.includes('add_permission') &&
          data.user_permissions.includes('remove_permission') &&
          hasInactiveRolesToDisable)
      ) {
        disabledRoles.push(id);
      }
    });

    return disabledRoles;
  };

  const toggleRoleSelected = (
    meta: Parameters<Exclude<RoleSelectProps['onChange'], undefined>>[1],
  ) => {
    if (meta?.action === 'clear') {
      const values = meta.removedValues?.map((option) => option.value);
      if (data.permissions.roles[formKey][collaboratorId]) {
        if (
          data.permissions.roles[formKey][collaboratorId].every((permissionId: string) =>
            values.includes(permissionId),
          )
        ) {
          if (isLastPermission(collaboratorId, collaboratorType, true)) {
            openDeleteLastPermissionModal(collaboratorId, collaboratorType);
          } else {
            removeRoles({
              objectId: data.id,
              objectType: data.type,
              params: {
                [collaboratorType]: collaboratorId,
                recursive,
              },
            });
          }
        }
      }
    } else {
      const value = meta?.action === 'remove-value' ? meta?.removedValue : meta?.option;

      if (value?.value) {
        if (!getDisabledRoles().includes(value?.value)) {
          if (
            data.permissions.roles[formKey][collaboratorId] &&
            data.permissions.roles[formKey][collaboratorId].includes(value.value)
          ) {
            if (data.permissions.roles[formKey][collaboratorId].length === 1) {
              if (isLastPermission(collaboratorId, collaboratorType)) {
                openDeleteLastPermissionModal(collaboratorId, collaboratorType);
              } else {
                removeRoles({
                  objectId: data.id,
                  objectType: data.type,
                  params: {
                    [collaboratorType]: collaboratorId,
                    recursive,
                  },
                });
              }
            } else {
              removeRoles({
                objectId: data.id,
                objectType: data.type,
                params: {
                  [collaboratorType]: collaboratorId,
                  roles: [value.value],
                  recursive,
                },
              });
            }
          } else {
            addRoles({
              objectId: data.id,
              objectType: data.type,
              params: {
                [collaboratorType]: collaboratorId,
                roles: [value.value],
                recursive,
              },
            });
          }
        }
      }
    }
  };

  if (collaborator.isOwner) {
    return <FormattedMessage id="global.owner" />;
  }

  if (!canEditPermissions) {
    return <div>{getRolesName(userRoles)}</div>;
  }

  return (
    <RoleSelect
      disabled={!canEditRoles(collaborator.collaboratorId, userRoles.length > 0)}
      width="25rem"
      minMenuHeight={256}
      menuPlacement="auto"
      size="medium"
      // @ts-ignore
      value={userRoles}
      onChange={(_, meta) => toggleRoleSelected(meta)}
      options={parsedRoles?.filter((role) => {
        return !getDisabledRoles().some((disabledRole) => disabledRole === role.value);
      })}
      menuPortalTarget={document.getElementById('PermissionsTable') ?? document.body}
      clearable
      key={collaboratorId}
      testId={`collaborator-${collaboratorId}-roles`}
    />
  );
};

export default RolesColumn;
