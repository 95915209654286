import { useEffect, useRef } from 'react';

import { useDispatch, useEnvisionTheme, useFavicon, useSelector } from '_common/hooks';
import { InstanceService } from '_common/services';
import PDFContextProvider from './PDFContext';
import PDFPermisionsContextProvider from './PDFPermissionsContext';
import usePDFData from './hooks/usePDFData';

import { selectIsIEnvision } from 'App/redux/appSlice';
import { setCurrentDocument } from 'App/redux/onboardingSlice';
import { SuiteProvider } from '_common/suite';

import Sidebar from './Sidebar/Sidebar';
import Title from './Title/Title';
import Menu from './Menu';
import Footer from './Footer';
import Content from './Content/Content';
import Navigation from './Navigation';
import RightSidePanel from './RightSidePanel/RightSidePanel';
import OnboardingPDF from './components/OnboardingPDF/OnboardingPDF';
import OnboardingPDFIntegration from './components/OnboardingPDF/OnboardingPDFIntegration';
import usePDFPersist from './hooks/usePDFPersist';

import styles from './PDF.module.scss';
import PDFModalConductor from './PDFModalConductor';
import { DocumentTitle } from '_common/components';
import { useOnboardingPDFActive } from '_common/hooks/Onboarding';

const PDF = () => {
  const dispatch = useDispatch();
  const pdfData = usePDFData({ open: true });
  useEnvisionTheme();
  usePDFPersist();

  const timeoutRef = useRef<number>();

  const onboardingPDFIsActive = useOnboardingPDFActive();
  const leftPanel = useSelector((state) => state.pdf.general.leftPanel);
  const rightPanel = useSelector((state) => state.pdf.general.rightPanel);
  const zoom = useSelector((state) => state.pdf.general.zoom);
  const isEnvision = useSelector(selectIsIEnvision);

  const handleObjectProcessing = ({
    objectId,
    objectType,
  }: {
    objectId: ObjectId;
    objectType: ObjectType;
  }) => {
    timeoutRef.current = window.setTimeout(() => {
      new InstanceService().getObjectStatus(objectType, objectId).then(({ data }) => {
        if (data.status !== 'processing' && pdfData) {
          if (data.id !== pdfData.id) {
            dispatch(setCurrentDocument({ target: 'dopdf', id: pdfData?.id, zoom }));
            window.open(`/pdf/${data.id}`, '_self');
          } else {
            dispatch(setCurrentDocument({ target: 'dopdf', zoom, isOnboarding: true }));
          }
        } else {
          handleObjectProcessing({ objectId: data.id, objectType: objectType });
        }
      });
    }, 2500);
  };

  useEffect(() => {
    if (onboardingPDFIsActive && pdfData) {
      new InstanceService().getPDFOnboarding().then(({ data }) => {
        if (data.status === 'processing') {
          handleObjectProcessing({ objectId: data.id, objectType: 'dopdf' });
        }
        if (data.status === 'active') {
          if (data.id !== pdfData.id) {
            dispatch(setCurrentDocument({ target: 'dopdf', id: pdfData.id, zoom }));
            window.open(`/pdf/${data.id}`, '_self');
          } else {
            dispatch(setCurrentDocument({ target: 'dopdf', zoom, isOnboarding: true }));
          }
        }
      });
    }

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [onboardingPDFIsActive, pdfData]);

  useFavicon('/favicon-red.ico');

  return (
    <div className={styles.container}>
      <DocumentTitle title={pdfData?.name ?? 'doDOC'}>
        <SuiteProvider app="dopdf">
          <PDFPermisionsContextProvider>
            <PDFContextProvider>
              <Title />
              <Menu />
              <Content />
              {leftPanel !== '' && <Navigation />}
              {rightPanel && <RightSidePanel />}
              <Sidebar />
              <Footer />
              {isEnvision ? <OnboardingPDFIntegration /> : <OnboardingPDF />}
              <PDFModalConductor />
            </PDFContextProvider>
          </PDFPermisionsContextProvider>
          <div id="pdf-overlay" />
        </SuiteProvider>
      </DocumentTitle>
    </div>
  );
};

export default PDF;
