import { GHOST_USERS } from '_common/services/api/publicProfilesApi';
import usePDFData from './usePDFData';
import { useOnboardingPDFActive } from '_common/hooks/Onboarding';

const usePDFCollaborators = () => {
  const pdf = usePDFData();

  const onboardingPDFIsActive = useOnboardingPDFActive();
  if (pdf) {
    const collaborators: string[] = onboardingPDFIsActive
      ? [GHOST_USERS.davidBean['id']]
      : [...pdf.owners, ...(pdf.shared_with ?? [])];

    return collaborators;
  }

  return [];
};

export default usePDFCollaborators;
