import { createSlice, createAsyncThunk, PayloadAction, isAnyOf } from '@reduxjs/toolkit';
import { isOnboardingExplorerActive } from '_common/hooks/Onboarding';
import { persistReducer } from 'redux-persist';
import localStorage from 'redux-persist/lib/storage';

export type TableSliceState = {
  identity: {
    [Property in Table.Identity]: {
      list: ObjectList;
      hasNextPage: boolean;
      isNextPageLoading: boolean;
      total?: number;
    };
  };
  selected: Record<ObjectId, boolean>;
  selectionQueue: {
    [identity in Table.Identity]?: {
      [objectId in ObjectId]: boolean;
    };
  };
  anchor: number;
  request: Request.AllListParams;
  contentLoading?: Table.Loading;
};
export const LOADING_STATE = {
  INITIAL: 'INITIAL',
  ORDER: 'ORDER',
  FILTER: 'FILTER',
};
const SLICE_NAME = 'TABLE';

export const REQUEST_INITIAL_STATE: Request.AllListParams = {
  offset: 0,
  size: 200,
  filter_fields: [],
  filter_values: [],
  order_field: '_id',
  order_type: 'desc',
};

export const INITIAL_STATE: TableSliceState = {
  contentLoading: undefined,
  identity: {
    'userManagement/users': {
      list: [],
      hasNextPage: false,
      isNextPageLoading: false,
    },
    'userManagement/tokens': {
      list: [],
      hasNextPage: false,
      isNextPageLoading: false,
    },
    groups: {
      list: [],
      hasNextPage: false,
      isNextPageLoading: false,
    },
    search: {
      list: [],
      hasNextPage: false,
      isNextPageLoading: false,
    },
    publicLink: {
      list: [],
      hasNextPage: false,
      isNextPageLoading: false,
    },
    shared: {
      list: [],
      hasNextPage: false,
      isNextPageLoading: false,
    },
    personal_spaces: {
      list: [],
      hasNextPage: false,
      isNextPageLoading: false,
    },
    recycle: {
      list: [],
      hasNextPage: false,
      isNextPageLoading: false,
    },
    spaces: {
      list: [],
      hasNextPage: false,
      isNextPageLoading: false,
    },
    storage: {
      list: [],
      hasNextPage: false,
      isNextPageLoading: false,
    },
    groups_admins: {
      list: [],
      hasNextPage: false,
      isNextPageLoading: false,
    },
    groups_members: {
      list: [],
      hasNextPage: false,
      isNextPageLoading: false,
    },
    auditLog: {
      list: [],
      hasNextPage: false,
      isNextPageLoading: false,
    },
    bullet_list: {
      list: [],
      hasNextPage: false,
      isNextPageLoading: false,
    },
    numbered_list: {
      list: [],
      hasNextPage: false,
      isNextPageLoading: false,
    },
    outline_list: {
      list: [],
      hasNextPage: false,
      isNextPageLoading: false,
    },
    status: {
      list: [],
      hasNextPage: false,
      isNextPageLoading: false,
    },
    templates: {
      list: [],
      hasNextPage: false,
      isNextPageLoading: false,
    },
    refStyles: {
      list: [],
      hasNextPage: false,
      isNextPageLoading: false,
    },
    roles: {
      list: [],
      hasNextPage: false,
      isNextPageLoading: false,
    },
    'userPreferences/security_session': {
      list: [],
      hasNextPage: false,
      isNextPageLoading: false,
    },
    'userPreferences/security_devices': {
      list: [],
      hasNextPage: false,
      isNextPageLoading: false,
    },
    'userPreferences/authentication': {
      list: [],
      hasNextPage: false,
      isNextPageLoading: false,
    },
    references: {
      list: [],
      hasNextPage: false,
      isNextPageLoading: false,
    },
  },
  selected: {},
  selectionQueue: {},
  anchor: 0,
  request: {
    ...REQUEST_INITIAL_STATE,
  },
};

// #region AsyncThunks
type ListObjectsParams = {
  identity: Table.Identity;
  fetch: (listParams: Request.AllListParams) => MyAny;
  request: Partial<Request.AllListParams>;
  cause?: Table.Loading;
};
export const listObjects = createAsyncThunk(
  `${SLICE_NAME}/listObjects`,
  async ({ identity, fetch, request }: ListObjectsParams, { getState }) => {
    const getPersistedRequestState = () => {
      const localStorageItem = window.localStorage.getItem(identity);
      if (!localStorageItem) {
        return {};
      }

      return JSON.parse(localStorageItem); // Holds order
    };
    const validRequest = {
      ...(getState() as RootState).table?.request,
      ...getPersistedRequestState(),
      ...request,
    };
    const { data: payload } = await fetch(validRequest);
    const actionsCompleted = (getState() as RootState).onboarding.actionsCompleted;
    const onboardingExplorerIsActive = isOnboardingExplorerActive(getState() as RootState);

    const dict: ObjectDict = {};
    const list: ObjectList = [];

    if (
      onboardingExplorerIsActive &&
      payload.nodes_length === 0 &&
      (identity === 'storage' || identity === 'shared') &&
      !actionsCompleted.explorer_open_space
    ) {
      const exampleDocument: Objekt = {
        attachments: { 1: { name: 'attachment.png' } },
        authors: [],
        content: {
          citations: 'CIT61b39192cbbac2966d72f95e',
          comments: 'CS61b39192cbbac2966d72f95e',
          notes: 'NTS61b39192cbbac2966d72f95e',
          structure: 'DS61b39192cbbac2966d72f95e',
        },
        creator: '5',
        description: '',
        events: { due: null, warnings: Array(0) },
        has_source: false,
        id: 'document1',
        keywords: [],
        lifecycle: {},
        metadata: {},
        links: [],
        name: 'Example Document',
        owner: '5',
        owners: ['5'],
        parent: null,
        permissions: {
          groups: {},
          roles: { groups: {}, users: {} },
          users: {},
        },
        shared_with: [],
        status: 'active',
        tags: [],
        time: {
          access: '2023-06-13T15:47:35.436000+00:00',
          creation: '2023-06-13T15:47:35.436000+00:00',
          modification: '2023-06-13T15:47:35.436000+00:00',
        },
        tracking: {
          state: false,
          lock: false,
        },
        type: 'document',
        user_permissions: ['admin', 'owner'],
      };
      const id = exampleDocument.id;
      dict[id] = exampleDocument;
      list.push(id);
    } else {
      payload.nodes?.forEach((node: Objekt) => {
        const id = node.pk || node.id || node._id || node.level;

        dict[id] = node;
        list.push(id);
      });
    }

    // Save ORDER on localStorage
    localStorage.setItem(
      identity,
      JSON.stringify({
        order_type: validRequest?.order_type,
        order_field: validRequest?.order_field,
      }),
    );

    return {
      identity,
      list,
      request: validRequest,
      dict,
      dataStorage: payload.dataStorage || 'app',
      total: payload.nodes_length,
    };
  },
);

export const lazyLoad = createAsyncThunk(
  `${SLICE_NAME}/lazyLoad`,
  async ({ identity, fetch, request }: ListObjectsParams, { getState }) => {
    const validRequest = { ...(getState() as RootState).table?.request, ...request };

    const { data: payload } = await fetch(validRequest);

    const data: ObjectDict = {};
    const list: ObjectId[] = [];
    payload.nodes.forEach((node: Objekt) => {
      const id = node.pk || node.id;
      data[id] = node;
      list.push(id);
    });

    return {
      data,
      identity,
      list,
      dataStorage: payload.dataStorage || 'app',
    };
  },
);

// #endregion

// #region Selectors
// #endregion

// #region Slice
const TableSlice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    setSelected: (state, action: PayloadAction<TableSliceState['selected']>) => {
      state.selected = action.payload;
    },
    toggleSelection: (
      state,
      action: PayloadAction<{ identity: Table.Identity; objectId: ObjectId }>,
    ) => {
      const { identity, objectId } = action.payload;

      if (!state.selected) {
        state.selected = {};
      }

      if (state.selected[objectId]) {
        delete state.selected[objectId];
      } else {
        state.selected[objectId] = true;
      }
      state.anchor = state.identity[identity].list.indexOf(objectId);
    },
    singleSelection: (
      state,
      action: PayloadAction<{ identity: Table.Identity; objectId: ObjectId }>,
    ) => {
      const { identity, objectId } = action.payload;

      state.selected = { [objectId]: true };
      state.anchor = state.identity[identity].list.indexOf(objectId);
    },
    groupSelection: (
      state,
      action: PayloadAction<{ identity: Table.Identity; objectId: ObjectId }>,
    ) => {
      const { identity, objectId } = action.payload;
      const indexOfObject = state.identity[identity].list.indexOf(objectId);

      if (!state.selected) {
        state.selected = {};
      }

      for (
        let i = Math.min(state.anchor, indexOfObject);
        i <= Math.max(state.anchor, indexOfObject);
        i++
      ) {
        state.selected[state.identity[identity].list[i]] = true;
      }
    },
    multipleSelection: (state, action: PayloadAction<{ objectsId: ObjectList }>) => {
      const { objectsId } = action.payload;
      objectsId.forEach((id) => {
        state.selected[id] = true;
      });

      state.anchor = 0;
    },
    clearSelection: (state) => {
      state.selected = {};
      state.anchor = 0;
    },
    addToSelectionQueue: (
      state,
      action: PayloadAction<{ identity: Table.Identity; objectId: ObjectId }>,
    ) => {
      const { identity, objectId } = action.payload;
      const objectIsListed = state.identity[identity]?.list.includes(objectId);

      if (objectIsListed) {
        state.selected = { [objectId]: true };
        state.anchor = state.identity[identity].list.indexOf(objectId);
      }

      state.selectionQueue = {
        ...INITIAL_STATE.selectionQueue,
        [identity]: { [objectId]: objectIsListed ? true : false },
      };
    },

    clearRequest: (state) => {
      state.request = { ...REQUEST_INITIAL_STATE };
    },
    addToList: (
      state,
      action: PayloadAction<{ identity: Table.Identity; objectId: ObjectId; afterId?: string }>,
    ) => {
      const { identity, objectId, afterId } = action.payload;

      if (afterId) {
        const index = state.identity[identity].list.findIndex((id) => id === afterId);
        state.identity[identity].list.splice(index + 1, 0, objectId);
      } else {
        state.identity[identity].list.unshift(objectId);
      }

      state.identity[identity].total = (state.identity[identity].total ?? 0) + 1;
    },
    addToListBulk: (
      state,
      action: PayloadAction<{ identity: Table.Identity; objects: ObjectList }>,
    ) => {
      const { identity, objects } = action.payload;

      const newList = objects.concat(state.identity[identity].list);
      state.identity[identity].list = newList.filter((object, index) => {
        return newList.indexOf(object) === index;
      });
      state.identity[identity].total = (state.identity[identity].total ?? 0) + objects.length;
    },
    removeFromList: (
      state,
      action: PayloadAction<{ identity: Table.Identity; objectId: ObjectId | ObjectId[] }>,
    ) => {
      const { identity, objectId } = action.payload;
      if (Array.isArray(objectId)) {
        objectId.forEach((id) => {
          const indexOfObject = state.identity[identity].list.indexOf(id);

          state.identity[identity].list.splice(indexOfObject, 1);
        });

        state.identity[identity].total = (state.identity[identity].total ?? 0) - objectId.length;
      } else {
        const indexOfObject = state.identity[identity].list.indexOf(objectId);

        state.identity[identity].list.splice(indexOfObject, 1);
        state.identity[identity].total = (state.identity[identity].total ?? 0) - 1;
      }
    },
    setList: (
      state,
      action: PayloadAction<{ identity: Table.Identity; list: ObjectId[]; total: number }>,
    ) => {
      const { identity, list, total } = action.payload;

      state.identity[identity].list = list;
      state.identity[identity].total = total;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listObjects.fulfilled, (state, action) => {
      const { identity, list, request, total } = action.payload;

      state.identity[identity] = {
        list,
        hasNextPage: false,
        isNextPageLoading: false,
      };

      //Select objectIds that are in the selection queue
      const identitySelectionQueue = state.selectionQueue[identity];
      if (identitySelectionQueue) {
        Object.keys(identitySelectionQueue).forEach((objectIdToSelect) => {
          const objectId = list.find((objectId) => objectId === objectIdToSelect);
          if (objectId && list.includes(objectId)) {
            delete identitySelectionQueue[objectIdToSelect];
            state.selected = { [objectId]: true };
            state.anchor = state.identity[identity].list.indexOf(objectId);
          }
        });
      }

      state.identity[identity].hasNextPage = list.length === request.size;
      state.identity[identity].isNextPageLoading = false;
      state.request = {
        ...request,
        offset: state.identity[identity].list.length,
      };
      state.identity[identity].total = total;
    });
    builder.addCase(lazyLoad.fulfilled, (state, action) => {
      const { identity, list } = action.payload;

      state.identity[identity].list = state.identity[identity].list.concat(
        list.filter((objectId) => !state.identity[identity].list.includes(objectId)),
      );

      //Select objectIds that are in the selection queue

      const identitySelectionQueue = state.selectionQueue[identity];
      if (identitySelectionQueue) {
        Object.keys(identitySelectionQueue).forEach((objectIdToSelect) => {
          const objectId = list.find((objectId) => objectId === objectIdToSelect);
          if (objectId) {
            delete identitySelectionQueue[objectIdToSelect];
            state.selected = { [objectId]: true };
            state.anchor = state.identity[identity].list.indexOf(objectId);
          }
        });
      }

      state.identity[identity].hasNextPage = list.length >= state.request.size;
      state.request.offset = list.length + (action.meta.arg.request.offset ?? 0);
    });
    builder.addCase(listObjects.pending, (state, action) => {
      if (action.meta.arg.cause === 'INITIAL' || action.meta.arg.cause === 'FILTER') {
        const identitySelectionQueue = state.selectionQueue[action.meta.arg.identity];

        if (identitySelectionQueue) {
          const selectionQueue = Object.keys(identitySelectionQueue);

          if (selectionQueue.length === 0) {
            state.selected = {};
            state.anchor = 0;
          } else {
            //Select objectIds that are in the selection queue
            selectionQueue.forEach((objectIdToSelect) => {
              if (identitySelectionQueue[objectIdToSelect]) {
                //delete state.selected[objectIdToSelect];
                if (!state.selected[objectIdToSelect]) {
                  delete identitySelectionQueue[objectIdToSelect];
                }
              }
              //state.anchor =
              //state.identity[action.meta.arg.identity].list.indexOf(objectIdToSelect);
            });
          }
        } else {
          state.selected = {};
          state.anchor = 0;
        }
      }

      state.contentLoading = action.meta.arg.cause;
    });

    builder.addMatcher(isAnyOf(listObjects.fulfilled, listObjects.rejected), (state) => {
      state.contentLoading = undefined;
    });
  },
});

// Persistence
const persistConfig = {
  key: 'table',
  storage: localStorage,
  whitelist: ['selectionQueue'],
};

const tableReducer = persistReducer(persistConfig, TableSlice.reducer);

export default tableReducer;

// #endregion

// Actions
export const {
  setSelected,
  toggleSelection,
  singleSelection,
  groupSelection,
  multipleSelection,
  clearSelection,
  addToSelectionQueue,
  clearRequest,
  addToList,
  addToListBulk,
  removeFromList,
  setList,
} = TableSlice.actions;
